
import Vue from 'vue'
export default Vue.extend({

	components: {
		DetailProjectHome: () => import('./../components/DetailProjectHome.vue'),
		DetailProjectDeployment: () => import('./../components/DetailProjectDeployment.vue'),
		DetailProjectSecurity: () => import('./../components/DetailProjectSecurity.vue'),
		DetailProjectNotes: () => import('./../components/DetailProjectNotes.vue'),
		DetailProjectWatsDeploymentProcedure: () => import('./../components/DetailProjectWatsDeploymentProcedure.vue')
	},

	data() {
		return {
			tab: 'home',
		}
	},
	
	computed: {
		project() {
			return this.$store.getters['listProjects/project'](this.$route.params.id)
		},

		isLoaded() {
			return this.$store.getters['projectForm/id'] !== null ? true : false
		},

		tabs() {
			const tabs = ['home', 'security step', 'deployment step', 'notes']

			if(this.project.securityStepDone == true && this.project.deploymentStepDone == true) {
				tabs.push('Wats deployment procedure')
			}

			return tabs
		}
	},

	watch: {
		project: {
			immediate: true,
			deep: true,
			handler(v: any) {
				if(v) {
					this.$store.dispatch('projectForm/load', this.$route.params.id)
					this.$store.dispatch('projectNotes/load', this.$route.params.id)
				}
			}
		}
	}

})
